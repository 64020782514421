export const TableColumn = [
  {
    key: 'solutionHub',
    name: 'Solution Hub',
    dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: false, // 固定列的位置(left, right)
    width: 200 // 默认宽度，像素
  },
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'internalBrandName',
    name: 'Internal Brand Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'opsName',
    name: 'Ops',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'payrollName',
    name: 'Payroll',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 100
  },
  {
    key: 'legalCodeName',
    name: 'Legal Entity Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'businessUnitId',
    name: 'Business Unit ID',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'businessUnitName',
    name: 'Business Unit Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'itName',
    name: 'IT',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'adminName',
    name: 'Admin',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'workCityEn',
    name: 'City',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 200
  },
  {
    key: 'prohibitStatus',
    name: '状态',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false,
    width: 80
  }
]
