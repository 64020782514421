<template>
  <div class="organization">
    <PocCardTable class="topPocCardTable">
      <template v-slot:headerTitle>机构管理</template>
      <template v-slot:headerGroup>
        <el-button
              :loading="queryLoading"
              @click="exportData"
              role="export"
              v-permission
              >导出</el-button
            >
        <el-button type="primary" @click="search">查询</el-button>
      </template>
      <!--      <PocSlider minheight="59px" maxHeight="300px" btntype="primary">-->
      <el-form
        :inline="true"
        :model="formInline"
        label-position="top"
        class="ogForm minWidthInput"
      >
        <el-form-item label="Solution Hub">
          <el-input
            v-model="formInline.solutionHub"
            placeholder="Solution Hub"
          ></el-input>
        </el-form-item>
        <el-form-item label="HFM Code">
          <el-input
            v-model="formInline.hfmCode"
            placeholder="HFM Code"
          ></el-input>
        </el-form-item>
        <el-form-item label="Internal Brand Name">
          <el-input
            v-model="formInline.internalBrandName"
            placeholder="Internal Brand Name"
          ></el-input>
        </el-form-item>
        <el-form-item label="Work City">
          <el-select
            v-model="formInline.workCity"
            clearable
            placeholder="Work City"
          >
            <el-option
              v-for="item in workCityList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="IT">
          <el-input v-model="formInline.it" placeholder="IT"></el-input>
        </el-form-item>
        <el-form-item label="Admin">
          <el-input v-model="formInline.admin" placeholder="Admin"></el-input>
        </el-form-item>
        <el-form-item label="Ops">
          <el-input v-model="formInline.ops" placeholder="Ops"></el-input>
        </el-form-item>
        <el-form-item label="PayRoll">
          <el-input
            v-model="formInline.payroll"
            placeholder="PayRoll"
          ></el-input>
        </el-form-item>
      </el-form>
      <!--      </PocSlider>-->
    </PocCardTable>
    <PocCardTable>
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        :max-height="10000000"
        @selection-change="handleSelectionChange"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @row-click="rowClick"
        @select-all="selectAll"
        highlight-current-row
      >
        <el-table-column type="selection" width="50" />
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        >
          <template v-if="item.name == '状态'" v-slot="{row}">
            <span>{{row.prohibitStatus == 1 ? '' : '已禁用'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="120" fixed="right" align="center">
          <!--                role="edit"
            v-permission-->
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="top"
            >
              <img
                class="iconImg pointer"
                role="edit"
                v-permission
                src="@/assets/img/edit.png"
                @click="onEdit(scope.row)"
                alt="编辑"
              />
            </el-tooltip>
            <el-tooltip
              style="margin-left: 20px"
              class="item"
              effect="dark"
              content="复制"
              placement="top"
            >
              <img
                class="iconImg pointer"
                src="@/assets/img/copy.png"
                @click="copy(scope.row)"
                alt="复制"
              />
            </el-tooltip>
            <el-tooltip
              style="margin-left: 20px"
              class="item"
              effect="dark"
              content="禁用"
              placement="top"
              v-if="scope.row.prohibitStatus == 1"
            >
            
              <el-popconfirm
                title="确定禁用机构？"
                confirm-button-text="确认"
                cancel-button-text="取消"
                @confirm="changeStatus(scope.row, false)"
              >
                <img
                  slot="reference"
                  class="iconImg pointer"
                  src="@/assets/img/disabled.png"
                  alt="禁用"
                />
              </el-popconfirm>
            </el-tooltip>
            <el-tooltip
              style="margin-left: 20px"
              class="item"
              effect="dark"
              content="启用"
              placement="top"
              v-else
            >
              <el-popconfirm
                title="确定启用机构？"
                confirm-button-text="确认"
                cancel-button-text="取消"
                @confirm="changeStatus(scope.row, true)"
              >
                <img
                  slot="reference"
                  class="iconImg pointer"
                  src="@/assets/img/enable.png"
                  alt="启用"
                />
              </el-popconfirm>
              <!-- <img
                class="iconImg pointer"
                src="@/assets/img/enable.png"
                @click="changeStatus(scope.row, true)"
                alt="启用"
              /> -->
            </el-tooltip>
          </template>
        </el-table-column>
      </PocTable>
      <DialogForm ref="DialogFormRef" @onConfirm="onConfirm" />
    </PocCardTable>

    <el-dialog
      :visible.sync="ModalFlagSuccess"
      width="354px"
      height="200px"
      top="230px"
      title="导出提示"
    >
      <div style="text-align: center; font-size: 20px">
        {{ exportMsg }}已成功导出！
      </div>
      <div style="text-align: center; margin-top: 38px;padding-bottom: 20px;">
        <el-button type="primary" @click="close"
          >知道了（{{ exportLeftSecond }}S）</el-button
        >
        <div class="clear"></div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="ModalDisabled"
      width="354px"
      height="200px"
      top="300px"
    >
      <div style="text-align: center; font-size: 14px">是否要禁用当前机构？</div>
    </el-dialog>
  </div>
</template>

<script>
import { TableColumn } from '@/views/organization/column'
import {
  cityList,
  getUserListByType,
  orgOrganizationsList,
  changeOrganizationStatus
} from '@/api/organization'
import DialogForm from '@/components/DialogForm/DialogForm'
import { orgOrganizationsEdit } from '@/api/organization'
import PocSlider from '@/components/poc-slider/poc-slider'
import { mapGetters } from 'vuex'
import { exportDataList3 } from '@/api/dataMain'

const { Message } = $PCommon
export default {
  name: 'organization',
  mixins: [$PCommon.TableMixin],
  components: {
    DialogForm,
    PocSlider
  },
  data() {
    return {
      exportLeftSecond: 3,
      exportMsg:'',
      ModalFlagSuccess:false,
      queryLoading:false,
      loading: false,
      columnConfig: TableColumn,
      tableData: [],
      formInline: {
        solutionHub: '',
        hfmCode: '',
        internalBrandName: '',
        workCity: '',
        it: '',
        admin: '',
        ops: '',
        payroll: ''
      },
      workCityList: [],
      opsList: [],
      maxHeight: '',
      ModalDisabled: false,
      disabledData: {}
    }
  },
  computed: {
    ...mapGetters(['userRole'])
  },
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 400
  },
  mounted() {
    this.queryApi()
  },
  methods: {
    async exportData() {
      this.queryLoading = true
      if (this.pageConfig.total == 0) {
        this.$message({
          type: 'error',
          message: '报表数据为空，无法导出'
        })
        this.queryLoading = false
        return
      }
      console.log('导出------')
      // this.pageConfig.total = 0
      // await this.queryApi()
      console.log('8888888', this.pageConfig)
      await this.$Api.funds.exportDataList3({
        pageNum:1,
        pageSize:10
      })
      this.queryLoading = false
      this.exportShowTip()
    },
    exportShowTip() {
      this.exportMsg = '机构管理'
      this.ModalFlagSuccess = true
      this.exportLeftSecond = 3
      const timer = setInterval(() => {
        if (this.exportLeftSecond > 0) {
          this.exportLeftSecond--
        } else {
          clearInterval(timer)
          this.ModalFlagSuccess = false
        }
      }, 1000)
    },
    close() {
      this.ModalFlagSuccess = false
      this.exportLeftSecond = 3
    },
    onSubmit() {},
    onConfirm(val) {
      if (this.status === 'edit') {
        orgOrganizationsEdit(val).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('修改成功')
            this.queryApi()
          }
        })
      } else if (this.status === 'copy') {
        val.id = ''
        orgOrganizationsEdit(val).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('复制成功')
            this.queryApi()
          }
        })
      }
    },
    search() {
      this.queryApi()
    },
    async exportDownload() {
      const data = { permissionName: this.permissionName }
      await sysUserExport(data)
      this.$refs.DownloadDialogRef.show({
        exportMsg: '机构管理',
        exportLeftSecond: 3
      })
    },
    selectAll() {},
    rowClick(val) {},
    handleSelectionChange(val) {},
    onDelete(val) {},
    onEdit(val) {
      this.status = 'edit'
      this.$refs.DialogFormRef.show({
        id: val.id,
        status: 'edit'
      })
    },
    copy(val) {
      this.status = 'copy'
      this.$refs.DialogFormRef.show({
        id: val.id,
        status: 'copy'
      })
    },
    changeStatus(val, type) {
      let params = {
        id: val.id,
        prohibitStatus: type
      }
      changeOrganizationStatus(params).then(res => {
        if(res && res.code) {
          Message.success('操作成功')
          this.queryApi()
        }
      })
    },
    showDisabledModel(val) {
      this.disabledData = val;
      this.ModalDisabled = true;
    },

    queryApi() {
      const data = {
        ...this.formInline,
        pageNum: this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize
      }
      this.loading = true
      orgOrganizationsList(data).then((res) => {
        if (res && res.code === 200) {
          this.loading = false
          if (res.data && res.data.list) {
            this.tableData = res.data.list
            this.pageConfig.total = res.data.total
            console.log("aaaa---------",this.pageConfig)
          } else {
            console.log("aaaa---------")
            this.tableData = []
            this.pageConfig.total = 0
          }
        }
      })
      cityList().then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.workCityList = res.data
          }
        }
      })
      const data1 = {
        type: 'HROPS'
      }
      getUserListByType(data1).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.opsList = res.data
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.organization {
  .iconImg {
    width: 16px;
    height: 16px;
  }
  //.el-form {
  //  .el-form-item {
  //    margin-bottom: 0 !important;
  //  }
  //}
}
</style>
